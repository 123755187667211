import React from 'react';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import { getImage } from '~/helpers';
import { Subheading, HeadingLarge, BodyText } from '~/components/.base/headings';
import { LargeRedButton } from '~/components/.base/buttons';
import './Catering.scss';

import { Default, Mobile } from '~/components/.base/responsive';

const Catering = ({ homePageData, homepageImages, bgImages }) => {
  return homePageData ? (
    <div className="catering">
      <Image fluid={getImage(bgImages, 'blurred_smudge')} alt="" className="map-background" />
      <div className="d-flex">
        <div className="left half">
          <Subheading as="p" className="preHeading">
            {homePageData.cateringSectionSubtitle}
          </Subheading>
          <HeadingLarge as="h2">
            <span
              dangerouslySetInnerHTML={{
                __html: homePageData.cateringSectionTitle,
              }}
            ></span>
          </HeadingLarge>
        </div>
        <div className="right half">
          <BodyText>{homePageData.cateringSectionDesc.cateringSectionDesc}</BodyText>
          <LargeRedButton as={Link} to="/catering/">
            {homePageData.cateringSectionButtonText}
          </LargeRedButton>
        </div>
      </div>
      <div className="img-container">
        <Mobile>
          <Image
            fluid={homePageData.cateringSectionMobileImage.fluid}
            alt=""
            durationFadeIn={100}
          />
        </Mobile>

        <Default>
          <Image
            fluid={homePageData.cateringSectionDesktopImage.fluid}
            alt=""
            durationFadeIn={100}
          />
        </Default>
      </div>
    </div>
  ) : null;
};

export default Catering;
