import React, { useEffect } from 'react';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import { Heading, BodyText } from '~/components/.base/headings';
import { WhiteButton } from '~/components/.base/buttons';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';

const FoodBlock = ({ image, index, alignment, heading, text, buttonText, buttonLink }) => {
    const { ref, inView } = useInView({
        threshold: 0.33,
    });
    useEffect(() => {
        if (window && document && inView) {
            document.querySelector(`#food-block-${index}`).classList.add('visible');
        }
    }, [inView, index]);
    return (
        <div ref={ref} id={`food-block-${index}`} className={`food-block ${alignment}`}>
            <div className="block-image">
                <Image fixed={image.fixed} className="fixed" alt="" />
                <Image fluid={image.fluid} alt="" className="fluid" />
            </div>
            <div className="block-info">
                <Heading as="h3">{heading}</Heading>
                {text ? <BodyText>{text}</BodyText> : null}
                {buttonText && buttonLink ? (
                    <WhiteButton as={Link} to={buttonLink}>
                        {buttonText}
                    </WhiteButton>
                ) : null}
            </div>
        </div>
    );
};

export default FoodBlock;
