import React, { Fragment } from 'react';
import Image from 'gatsby-image';
import { getImage } from '~/helpers';
import FoodBlock from './FoodBlock';
import './FoodImages.scss';
import { HeadingLarge } from '~/components/.base/headings';
import { BackgroundDivBgImage } from '~/components/.base/containers';
import { WoodenBorder } from '../../.base/border';

const FoodImages = ({ homePageData, bgImages }) => {
    return (
        <Fragment>
            <WoodenBorder top="140px" topMd="300px" />
            <WoodenBorder top="688px" topMd="1238px" />
            <BackgroundDivBgImage
                w="100%"
                h="938px"
                heightMobile="538px"
                top="300px"
                topMobile="150px"
                color="#edf2f2"
                style={{ zIndex: '-2' }}
            />
            <div className="food-images">
                <Image
                    fluid={getImage(bgImages, 'blurred_smudge_4')}
                    alt=""
                    className="map-background"
                />
                <HeadingLarge as="h2" className="food-images-heading">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: homePageData.foodItemSectionTitle,
                        }}
                    ></span>
                </HeadingLarge>
                <div>
                    {homePageData.foodItems.map((item, i) => (
                        <FoodBlock
                            key={i}
                            index={i}
                            image={item.foodImage}
                            alignment={i % 2 === 0 ? 'right' : 'left'}
                            heading={item.foodTitle}
                            text={item.foodDesc}
                            buttonText={item.buttonText}
                            buttonLink={item.buttonLink}
                        />
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default FoodImages;
