import React, { useEffect, useCallback, useRef, useState } from 'react';
import Image from 'gatsby-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
import { css } from 'styled-components';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { getImage, getImageWidth } from '~/helpers';
import { Default, Mobile } from '../../.base/responsive';
import { Subheading, HeadingLarge } from '~/components/.base/headings';
import { LargeRedButton } from '~/components/.base/buttons';
import { breakpoint } from '../../../constants/Breakpoint';

// Hooks
import { useBubbleImages } from '../../../hooks/use-bubble-images';

import { CenteredContainer, BackgroundDiv } from '~/components/.base/containers';
import PhoneFrame from '~/images/icons/homepage/phone_frame.svg';
import './DeliveryApp.scss';
import { LargeStencil } from '~/components/.base/headings';
import PlayButton from '~/images/icons/mobileApp/play-btn.svg';
import PauseButton from '~/images/icons/mobileApp/pause-btn.svg';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const DeliveryApp = ({ homepageImages, homePageData, videoLink }) => {
  const { logoMobile } = useStaticQuery(graphql`
    {
      logoMobile: file(relativePath: { eq: "logos/logo_mobile.png" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed_withWebp
            aspectRatio
          }
        }
      }
    }
  `);
  const bubbleImages = useBubbleImages().edges;

  useEffect(() => {
    let scrollTriggers = [];

    let tl = gsap.timeline().to(`${LargeStencil}`, {
      x: '0',
    });
    let st = ScrollTrigger.create({
      // anticipatePin: 1,
      trigger: '.delivery-app',
      start: 'center center',
      end: 'center center-=75%',
      scrub: 0.5,
      animation: tl,
    });
    scrollTriggers.push(st);

    // Specify how to clean up after this effect:
    return () => {
      // all garbage cleanup all goes in a single useEffect for when component leaves
      if (scrollTriggers) {
        scrollTriggers.map(st => {
          st.kill();
          return true;
        });
      }
    };
  }, []);

  const [isVideoPlaying, setIsVideoPlaying] = useState(null);
  const [canPlayVideo, setCanPlayVideo] = useState(false);

  const videoWrapperRef = useRef(null);

  const toggleVideoPlaying = useCallback(() => {
    if (videoWrapperRef?.current?.querySelector('video')) {
      const videoElm = videoWrapperRef.current.querySelector('video');
      if (isVideoPlaying) {
        videoElm.pause();
        return;
      }
      if (canPlayVideo) {
        videoElm.play();
        return;
      }
    }
  }, [videoWrapperRef, isVideoPlaying, canPlayVideo]);

  useEffect(() => {
    if (videoWrapperRef?.current?.querySelector('video')) {
      const videoElm = videoWrapperRef.current.querySelector('video');
      const handleOnPlay = () => {
        setIsVideoPlaying(true);
      };
      const handleOnPause = () => {
        setIsVideoPlaying(false);
      };
      const handleOnCanPlay = () => {
        setCanPlayVideo(true);
      };
      const handleOnLoadedData = () => {
        setCanPlayVideo(true);
      };
      videoElm.addEventListener('play', handleOnPlay);
      videoElm.addEventListener('pause', handleOnPause);
      videoElm.addEventListener('canplay', handleOnCanPlay);
      videoElm.addEventListener('loadeddata', handleOnLoadedData);
      return () => {
        videoElm.removeEventListener('play', handleOnPlay);
        videoElm.removeEventListener('pause', handleOnPause);
        videoElm.removeEventListener('canplay', handleOnCanPlay);
        videoElm.removeEventListener('loadeddata', handleOnLoadedData);
      };
    }
  }, [videoWrapperRef, setIsVideoPlaying, setCanPlayVideo]);

  // Play/pause video when it enters or leaves the screen
  useEffect(() => {
    let st;
    if (videoWrapperRef?.current?.querySelector('video')) {
      st = ScrollTrigger.create({
        trigger: videoWrapperRef.current,
        start: 'top 60%',
        end: 'bottom 20%',
        invalidateOnRefresh: true,
        onToggle: ({ isActive }) => {
          const videoElm = videoWrapperRef.current.querySelector('video');
          if (isActive) {
            videoElm.play();
          } else {
            videoElm.pause();
          }
        },
      });
    }
    return () => {
      if (st) {
        st.kill();
      }
    };
  }, [videoWrapperRef]);

  return (
    <div className="delivery-app">
      <BackgroundDiv className="image-layer" top="0px" left="0px" w="100%">
        <Default>
          <Parallax className="water_sharp" y={['-100px', '-70px']}>
            <Image fluid={getImage(homepageImages, 'water-sharp')} alt="" />
          </Parallax>
        </Default>
        <Mobile>
          <Image fluid={getImage(homepageImages, 'water-sharp')} alt="" />
        </Mobile>
        <Default>
          <Parallax className="water_blurred" y={['-50px', '-100px']}>
            <Image fluid={getImage(homepageImages, 'water-blurred')} alt="" />
          </Parallax>
        </Default>
        <Mobile>
          <Image fluid={getImage(homepageImages, 'water-blurred')} alt="" />
        </Mobile>
      </BackgroundDiv>
      <Parallax className="bubbles_1" y={['0px', '-75px']}>
        <div
          css={css`
            width: 100%;
            @media (min-width: 950px) {
              width: ${getImageWidth(bubbleImages, 'bubbles_1')}px;
            }
          `}
        >
          <Image fluid={getImage(bubbleImages, 'bubbles_1')} alt="" />
        </div>
      </Parallax>
      <Default>
        <Parallax className="bubbles_2" y={['0px', '-300px']}>
          <div
            css={css`
              width: ${getImageWidth(bubbleImages, 'bubbles_2')}px;
            `}
          >
            <Image fluid={getImage(bubbleImages, 'bubbles_2')} alt="" />
          </div>
        </Parallax>
      </Default>
      <Default>
        <Parallax className="bubbles_3_top" y={['200px', '-200px']}>
          <div
            css={css`
              width: ${getImageWidth(bubbleImages, 'bubbles_3')}px;
            `}
          >
            <Image fluid={getImage(bubbleImages, 'bubbles_3')} alt="" />
          </div>
        </Parallax>
      </Default>
      <Default>
        <Parallax className="bubbles_3_bottom" y={['100px', '-100px']}>
          <div
            css={css`
              width: ${getImageWidth(bubbleImages, 'bubbles_3btm')}px;
            `}
          >
            <Image fluid={getImage(bubbleImages, 'bubbles_3btm')} alt="" />
          </div>
        </Parallax>
      </Default>
      <Default>
        <Parallax className="bubbles_4" y={['50px', '0px']}>
          <div
            css={css`
              width: ${getImageWidth(bubbleImages, 'bubbles_4')}px;
            `}
          >
            <Image fluid={getImage(bubbleImages, 'bubbles_4')} alt="" />
          </div>
        </Parallax>
      </Default>
      <Default>
        <Parallax className="bubbles_5" y={['50px', '0px']}>
          <div
            css={css`
              width: ${getImageWidth(bubbleImages, 'bubbles_5')}px;
            `}
          >
            <Image fluid={getImage(bubbleImages, 'bubbles_5')} alt="" />
          </div>
        </Parallax>
      </Default>
      <Default>
        <Parallax className="bubbles_6" y={['-50px', '50px']}>
          <div
            css={css`
              width: ${getImageWidth(bubbleImages, 'bubbles_6')}px;
            `}
          >
            <Image fluid={getImage(bubbleImages, 'bubbles_6')} alt="" />
          </div>
        </Parallax>
      </Default>
      <div id="homepageMobileapp" tabIndex="-1">
        <Subheading as="p" className="preHeading">
          {homePageData.mobileAppSectionSubtitle}
        </Subheading>
        <HeadingLarge as="h2">
          <span
            dangerouslySetInnerHTML={{
              __html: homePageData.mobileAppSectionTitle,
            }}
          ></span>
        </HeadingLarge>
        <div
          css={css`
            position: relative;
            overflow: hidden;
          `}
        >
          <LargeStencil
            as="p"
            css={css`
              right: 0;
              top: 50%;
              transform: translate(calc(3535px - 100vw), -50%);
              font-size: 600px;
              @media ${breakpoint.maxMd} {
                display: none;
              }
            `}
          >
            Mobile App
          </LargeStencil>
          <div className="img-container app-container">
            {homePageData.mobileAppFile.file.contentType === 'video/mp4' ? (
              <>
                <button
                  onClick={toggleVideoPlaying}
                  className={`videoControls ${isVideoPlaying ? 'pause' : 'play'}`}
                >
                  <span className="sr-only">{isVideoPlaying ? 'Pause video' : 'Play video'}</span>
                  {isVideoPlaying ? <PauseButton /> : <PlayButton />}
                </button>
                <div
                  ref={videoWrapperRef}
                  className="delivery-app-vid"
                  dangerouslySetInnerHTML={{
                    __html: `<video
                      width="100%"
                      height="auto"
                      preload="metadata"
                      muted
                      loop
                      playsinline
                      disablePictureInPicture
                    >
                      <source src="${videoLink}" type="video/mp4" />
                      Sorry, your browser does not support embedded videos.
                    </video>`,
                  }}
                />
              </>
            ) : null}
            <PhoneFrame className="frame" />
            <div className="shadow-container">
              <Image
                className="shadow-container-logo"
                fixed={logoMobile.childImageSharp.fixed}
                alt=""
              />
            </div>
          </div>
        </div>
        <CenteredContainer style={{ margin: '3rem auto' }}>
          <LargeRedButton as={Link} to="/mobile-app">
            {homePageData.mobileAppSectionButtonText}
          </LargeRedButton>
        </CenteredContainer>
      </div>
    </div>
  );
};

export default DeliveryApp;
