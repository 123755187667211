import styled, { keyframes } from 'styled-components';
import { breakpoint } from 'constants/Breakpoint';
import { Subheading, Heading } from 'components/.base/headings';

export const TextWithImageSection = styled.div`
  position: relative;
  // margin: 150px 0 0;
  padding: 45% 0 12rem;
  background-color: #edf2f2;

  .imageLayer {
    position: absolute;
    top: -20px;
    height: auto;
    z-index: 1;

    img,
    .gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }

    .water_blurred * {
      z-index: 1;
    }
  }

  .bubbles_1 {
    position: absolute;
    top: calc(-10vw - 100px);
    left: 35%;

    img {
      width: auto;
    }
  }

  .bubbles_2 {
    position: absolute;
    top: calc(-10vw + 100px);
    left: 0px;
  }

  .bubbles_3_top {
    position: absolute;
    top: calc(-10vw - 30px);
    right: 50px;
  }

  .bubbles_3_bottom {
    position: absolute;
    top: calc(-10vw + 500px);
    right: 50px;
  }

  .bubbles_4 {
    position: absolute;
    top: calc(-10vw + 200px);
    left: 250px;
  }

  .bubbles_5 {
    position: absolute;
    top: calc(-10vw + 650px);
    right: 350px;
  }

  .bubbles_6 {
    position: absolute;
    top: calc(-10vw + 730px);
    left: calc(50% - 200px);
    z-index: 3;
  }
  @media ${breakpoint.maxSm} {
    padding: 45% 0 3rem;
    margin-top: -2rem;

    .bubbles_1 {
      top: 50px;
      left: 5%;
      width: 95%;

      img {
        width: 100%;
      }
    }

    .bubbles_3_top,
    .bubbles_3_bottom,
    .bubbles_4,
    .bubbles_5 {
      display: none;
    }
  }
`;

export const SlideNav = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  right: 0;
  @media ${breakpoint.lg} {
    right: 40px;
  }
  .slideNav__inner {
    position: relative;
  }
`;

export const HeroCol = styled.div`
  width: 350px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media ${breakpoint.md} {
    margin: 0;
  }
`;

export const StoreWrapper = styled.a`
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
  padding: 23px 49px 23px 30px;
  border-radius: 20px;
  background-color: #1d1d1d;
  display: grid;
  grid-template-columns: 37px auto;
  grid-column-gap: 30px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
  }
  img {
    width: 100%;
  }
  ${Subheading}, ${Heading} {
    color: #fff;
    text-align: left;
    margin-bottom: 0;
  }
`;

export const PhoneFrameWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 584px;
  margin: 2rem auto 2rem;
  position: relative;
  svg {
    width: 100%;
    position: absolute;
  }
  .frame {
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 2;
  }
  .shadow {
    top: 0px;
    left: 0px;
    height: 110%;
    width: 110%;
  }
`;

export const FrameContentWrapper = styled.div`
  position: absolute;
  left: 19px;
  top: 10px;
  z-index: 1;
  height: calc(100% - 20px);
  width: calc(100% - 38px);
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
`;

export const fadeEffect = keyframes`
    from {opacity: 0;}
    to {opacity: 1;}
`;

export const SlideItem = styled.div`
  display: none;
  animation: ${fadeEffect} 1s;
  ${({ active }) =>
    active
      ? `
        display: block;
    `
      : ''}
`;
