import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { getImage, getImageFixed } from '~/helpers';
import { CenteredContainer } from '~/components/.base/containers';
import { useSpring, animated } from 'react-spring';
import { HeadingLarge, BodyText } from '~/components/.base/headings';
import DotTrailIcon from '~/images/icons/checkout/dot-trail.svg';
import { RedButton } from '~/components/.base/buttons';
import './ParallaxSlide.scss';
import { useMediaQuery } from 'react-responsive';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const transBlurredBoat = (x, y) => `translate3d(${x / 3.5}px,${y / 8}px,0)`;
const transFisherman = (x, y) => `translate3d(${x / -15}px,${y / -20}px,0)`;
const transGull = (x, y) => `translate3d(${x / -10}px,${y / -10}px,0)`;
const transBoat = (x, y) => `translate3d(${x / -10}px,${y / -15}px,0)`;
const transOcean = (x, y) => `translate3d(${x / -30}px,calc(-39% + ${y / -40}px),0)`;
const transLighthouse = (x, y) => `translate3d(${x / -35}px,${y / -45}px,0)`;

const ParallaxSlide = ({ homepageImages, slideData, homepageData }) => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
  const parallaxImages = useStaticQuery(graphql`
    query {
      mobileImages: allFile(
        filter: {
          absolutePath: { regex: "/images/homepage/slideshow/" }
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed(width: 263, height: 326) {
                aspectRatio
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
      smallerImages: allFile(
        filter: {
          absolutePath: { regex: "/images/homepage/slideshow/" }
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(
                maxWidth: 200
                quality: 80
                sizes: "(max-width: 500px) 6vw, (max-width: 1200px) 12vw, 200px"
              ) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      mediumImages: allFile(
        filter: {
          absolutePath: { regex: "/images/homepage/slideshow/" }
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 80, sizes: "(max-width: 500px) 30vw, 500px") {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      largerImages: allFile(
        filter: {
          absolutePath: { regex: "/images/homepage/slideshow/" }
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div
      className="slide-img parallax-slide"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div
        className="blurred_boat_stuff"
        style={{ transform: props.xy.interpolate(transBlurredBoat) }}
      >
        <Image
          fluid={getImage(parallaxImages.largerImages.edges, 'blurred_boat_stuff')}
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
      </animated.div>
      <animated.div
        className="fisherman"
        style={{ transform: props.xy.interpolate(transFisherman) }}
      >
        <Image
          fixed={getImageFixed(parallaxImages.mobileImages.edges, 'fisherman')}
          className="fixed"
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
        <Image
          fluid={getImage(parallaxImages.mediumImages.edges, 'fisherman')}
          className="fluid"
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
      </animated.div>
      <animated.div
        className="fisherman_boat"
        style={{ transform: props.xy.interpolate(transBoat) }}
      >
        <Image
          fluid={getImage(parallaxImages.mediumImages.edges, 'fisherman_boat')}
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
      </animated.div>
      <animated.div
        className="landscape_water"
        style={{ transform: props.xy.interpolate(transOcean) }}
      >
        <Image
          fluid={getImage(parallaxImages.largerImages.edges, 'landscape_water_left')}
          className="landscape_water"
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
      </animated.div>
      <animated.div
        className="landscape_water"
        style={{ transform: props.xy.interpolate(transOcean) }}
      >
        <Image
          fluid={getImage(parallaxImages.largerImages.edges, 'landscape_water_right')}
          className="landscape_water"
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
      </animated.div>
      <animated.div
        className="lighthouse"
        style={{ transform: props.xy.interpolate(transLighthouse) }}
      >
        <Image
          fluid={getImage(parallaxImages.smallerImages.edges, 'lighthouse')}
          className="lighthouse"
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
      </animated.div>
      <animated.div className="seagull" style={{ transform: props.xy.interpolate(transGull) }}>
        <Image
          fluid={getImage(parallaxImages.smallerImages.edges, 'seagull')}
          alt=""
          loading="eager"
          fadeIn={false}
          durationFadeIn={100}
        />
      </animated.div>
      <CenteredContainer className="details">
        <HeadingLarge className="slide-title" md="0 0 2rem">
          <span>We’re bringing Maine Lobster</span>{' '}
          <span className="red">To your neighborhood</span>
        </HeadingLarge>

        {homepageData.showButtonOnParallaxSlide ? (
          <RedButton as={Link} to={homepageData.parallaxButtonLink} className="cta">
            {homepageData.parallaxButtonText}
          </RedButton>
        ) : null}
        <HeadingLarge as="p" className="indexes">
          <span class="sr-only">Current slide</span>
          <span>
            01
            <span className="sm">
              <span className="big-space">/</span> 0{slideData ? slideData.length + 1 : ''}
            </span>
          </span>
        </HeadingLarge>
      </CenteredContainer>
    </div>
  );
};
export default ParallaxSlide;
