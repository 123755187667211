import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';
import { WhiteButton } from './buttons';

const ModalBodyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const ModalBody = styled.div`
  top: ${props => props.top}%;
  position: relative;
  width: 90%;
  max-width: 1400px;
  max-width: ${props => props.modalMaxWidth};
  max-height: 100%;
  margin: auto;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({ mobileFullScreen }) =>
    mobileFullScreen &&
    `
          @media screen and (max-width: 1024px) {
              border-radius: 0px;
              width: 100%;
              height: 100%;
              max-width: none;
          }
    `}
`;
const ModalInner = styled.div`
  background-color: #fff;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ebedee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d4212c;
  }
`;

const CloseButton = styled(WhiteButton)`
  position: absolute;
  height: 40px;
  width: 40px;
  padding: 0px;
  right: 20px;
  top: 20px;
  border-radius: 20px;
  font-size: 24px;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    font-size: 24px;
  }
`;

const ModalComponent = ({
  open,
  onClose,
  ariaLabel,
  ariaLabelledby,
  ariaDescribedBy,
  top,
  modalMaxWidth,
  children,
  mobileFullScreen = true,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedBy}
      closeAfterTransition
      disableRestoreFocus={false}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalBodyContainer onClick={onClose}>
          <ModalBody
            onClick={e => e.stopPropagation()}
            top={top}
            modalMaxWidth={modalMaxWidth}
            className="modalBody"
            mobileFullScreen={mobileFullScreen}
          >
            <ModalInner>
              <CloseButton
                onClick={() => {
                  onClose();
                }}
                aria-label="Close"
              >
                &times;
              </CloseButton>

              {children}
            </ModalInner>
          </ModalBody>
        </ModalBodyContainer>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;
