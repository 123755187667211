import React from 'react';

import Lighthouse from './Lighthouse';
import PhoneShadow from './PhoneShadow';

export const ArrowRight = () => (
    <svg className="icon icon--arrowRight" viewBox="0 0 16 16" aria-hidden="true">
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
                <path
                    d="M9.867 10.063L12.398 7.531 9.867 5 9.867 7.098 3 7.098 3 7.965 9.867 7.965z"
                    transform="translate(-479 -1061) translate(40 583) translate(331) translate(0 466) translate(96) translate(12 12)"
                />
            </g>
        </g>
    </svg>
);

export const Download = () => (
    <svg className="icon icon--download" viewBox="0 0 16 16" aria-hidden="true">
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
                <path
                    d="M4 2c.276 0 .5.232.5.519 0 .286-.224.518-.5.518H2.5c-.827 0-1.5.698-1.5 1.556v8.814c0 .858.673 1.556 1.5 1.556h8c.827 0 1.5-.698 1.5-1.556V4.593c0-.858-.673-1.556-1.5-1.556H9.001c-.276 0-.5-.232-.5-.518 0-.287.224-.519.5-.519H10.5C11.879 2 13 3.163 13 4.593v8.814C13 14.837 11.879 16 10.5 16h-8C1.121 16 0 14.837 0 13.407V4.593C0 3.163 1.121 2 2.5 2H4zm2.5-2c.276 0 .5.224.5.5v8.793l2.147-2.146C9.24 7.052 9.367 7 9.5 7c.133 0 .26.052.353.147.095.094.147.22.147.353 0 .133-.052.26-.147.353l-3 3c-.023.024-.048.044-.076.063-.008.006-.024.015-.034.02-.007.005-.028.016-.05.025-.01.005-.031.012-.042.014-.013.005-.032.011-.052.015-.032.007-.065.01-.098.01-.034 0-.067-.003-.099-.01-.02-.004-.04-.01-.059-.017l-.033-.011c-.025-.01-.046-.022-.066-.034-.011-.005-.015-.008-.026-.015-.023-.016-.049-.036-.071-.06l-3-3C3.052 7.76 3 7.633 3 7.5c0-.133.052-.26.147-.353C3.24 7.052 3.367 7 3.5 7c.133 0 .26.052.353.147L6 9.293V.5c0-.276.224-.5.5-.5z"
                    transform="translate(-842 -4879) translate(475 4617) translate(355 250) translate(12 12) translate(1.5)"
                />
            </g>
        </g>
    </svg>
);

export const Facebook = () => (
    <svg className="icon icon--facebook" viewBox="0 0 16 16" aria-hidden="true">
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
                <path
                    d="M9.007 0l-.226.005C6.484.1 5.461 1.68 5.461 3.74v.929H3.993c-.272 0-.493.226-.493.503v2.665l.008.09c.042.235.243.412.485.412h1.468v7.159c0 .277.221.502.493.502h3.27l.088-.008c.23-.043.404-.248.404-.494V8.339h2.024c.253 0 .465-.195.49-.451l.269-2.665.001-.087c-.018-.258-.229-.466-.492-.466l-2.292-.001v-.77c-.007-.096.008-.142.039-.176.03-.034.074-.054.12-.053h1.963c.272 0 .493-.225.493-.503V.502c0-.277-.22-.502-.493-.502h-2.83zm2.338 1.004v1.66H9.877c-.323-.001-.633.138-.852.383-.219.245-.325.573-.293.903L8.73 5.172l.008.09c.042.235.243.413.485.413l2.238-.001-.166 1.66H9.223l-.089.008c-.23.043-.404.248-.404.495v7.158H6.446V7.837l-.007-.09c-.042-.235-.244-.413-.486-.413H4.486v-1.66h1.468c.273 0 .493-.224.493-.502V3.74c0-1.634.744-2.735 2.56-2.735l2.338-.001z"
                    transform="translate(-273 -1094) translate(261 691) translate(0 237) translate(0 54) translate(0 100) translate(12 12)"
                />
            </g>
        </g>
    </svg>
);

export const Instagram = () => (
    <svg className="icon icon--instagram" viewBox="0 0 16 16" aria-hidden="true">
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
                <path
                    d="M11 0c2.761 0 5 2.239 5 5v6c0 2.761-2.239 5-5 5H5c-2.761 0-5-2.239-5-5V5c0-2.761 2.239-5 5-5h6zm0 1H5C2.858 1 1.109 2.684 1.005 4.8L1 5v6c0 2.142 1.684 3.891 3.8 3.995L5 15h6c2.142 0 3.891-1.684 3.995-3.8L15 11V5c0-2.142-1.684-3.891-3.8-3.995L11 1zM6.209 4.423c1.197-.6 2.622-.562 3.791.113C11.238 5.25 12 6.57 12 8c0 1.43-.762 2.75-2 3.464-1.238.715-2.762.715-4 0C4.762 10.75 4 9.43 4 8c0-1.43.762-2.75 2-3.464zm3.291.979c-.928-.536-2.072-.536-3 0C5.572 5.938 5 6.928 5 8c0 1.072.572 2.062 1.5 2.598s2.072.536 3 0C10.428 10.062 11 9.072 11 8c0-1.072-.572-2.062-1.5-2.598zM13 2.634c.31.179.5.509.5.866s-.19.687-.5.866c-.31.179-.69.179-1 0-.31-.179-.5-.509-.5-.866s.19-.687.5-.866c.31-.179.69-.179 1 0z"
                    transform="translate(-273 -994) translate(261 691) translate(0 237) translate(0 54) translate(12 12)"
                />
            </g>
        </g>
    </svg>
);

export const Twitter = () => (
    <svg className="icon icon--twitter" viewBox="0 0 16 16" aria-hidden="true">
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
                <path
                    d="M11.097.5l-.193.006C9.052.61 7.582 2.148 7.578 4.032v.246l-.028.005c-1.845.2-3.61-.789-5.47-2.937-.275-.316-.792-.176-.87.237l-.046.276c-.183 1.186-.079 2.205.317 3.042l.067.13-.972-.147c-.327-.05-.611.226-.572.556l.028.188c.184 1.06.828 1.952 1.714 2.47l.03.017-.44.161c-.28.103-.41.428-.276.696l.084.16c.486.886 1.143 1.336 2.243 1.582l.055.011-.01.006c-.92.38-1.914.57-2.92.546-.546-.012-.713.738-.214.96l.28.122c7.741 3.286 14.013-.949 14.013-7.316V4.74l1.203-.874.071-.062c.232-.245.16-.664-.167-.805l-.94-.404.526-1.149.03-.08c.101-.372-.255-.734-.642-.605l-1.452.484-.103-.079C12.6.79 11.986.562 11.345.511L11.097.5zm-.163 1.009l.16-.007c.622.008 1.22.248 1.677.675l.07.055c.127.081.283.102.428.054l.627-.21-.26.569-.03.087c-.059.234.059.484.288.583l.599.257-.695.506c-.13.094-.207.245-.207.406v.56l-.003.224c-.13 4.998-4.636 8.427-10.664 6.84l-.265-.072.19-.047c.874-.24 1.707-.625 2.464-1.143.39-.267.228-.877-.243-.914l-.242-.02c-1.334-.121-2.047-.33-2.514-.847l-.05-.06 1.26-.46c.51-.186.4-.94-.141-.972l-.167-.015c-.881-.11-1.626-.67-1.993-1.449l-.051-.12 1.462.224c.492.075.78-.54.407-.871l-.138-.132c-.533-.543-.81-1.264-.827-2.186l.002-.208.165.165C4.157 4.845 6.092 5.63 8.179 5.198c.232-.049.399-.254.399-.492v-.673c.003-1.34 1.042-2.436 2.356-2.524z"
                    transform="translate(-273 -1044) translate(261 691) translate(0 237) translate(0 54) translate(0 50) translate(12 12) translate(0 1)"
                />
            </g>
        </g>
    </svg>
);

export const Youtube = () => (
    <svg className="icon icon--youtube" viewBox="0 0 16 16" aria-hidden="true">
        <g fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
                <path
                    d="M13.207 2.5c1.479 0 2.7 1.093 2.788 2.488L16 5.15v5.7c0 1.417-1.166 2.562-2.624 2.645l-.17.005H2.794c-.734 0-1.44-.274-1.963-.765-.482-.452-.774-1.053-.823-1.692L0 10.85v-5.7c0-1.417 1.166-2.562 2.624-2.645l.17-.005h10.413zm0 1H2.793c-.945 0-1.709.67-1.786 1.509L1 5.15v5.7c0 .43.183.846.514 1.156.294.276.684.447 1.1.486l.18.008h10.413c.945 0 1.709-.67 1.786-1.509L15 10.85v-5.7c0-.856-.714-1.571-1.638-1.644l-.155-.006zM6.512 4.583l.078.038 4.514 2.706c.297.178.321.588.074.804l-.074.054-4.514 2.706c-.307.185-.692-.006-.75-.342l-.007-.086V5.05c0-.359.361-.59.68-.467zm.32 1.349v3.647l3.042-1.823-3.041-1.824z"
                    transform="translate(-273 -1144) translate(261 691) translate(0 237) translate(0 54) translate(0 150) translate(12 12)"
                />
            </g>
        </g>
    </svg>
);

export const Secure = () => (
    <svg className="icon icon--secure" viewBox="0 0 32 32" aria-hidden="true">
        <g
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke="currentColor"
        >
            <path
                d="M-.002 4.373v10.49c0 6.787 4.201 12.874 10.568 15.31l1.441.551c.96.368 2.023.368 2.983 0l1.441-.55c6.366-2.437 10.567-8.524 10.567-15.311V4.373c.006-.807-.465-1.541-1.202-1.877C21.92.812 17.73-.038 13.498.001 9.268-.038 5.078.812 1.2 2.496c-.737.336-1.207 1.07-1.202 1.877z"
                transform="translate(-964 -995) translate(964 995) translate(2.5 .5)"
            />
            <path
                d="M.556 4L9.556 4 9.556 12 .556 12zM5.056 0h0c-1.381 0-2.5 1.194-2.5 2.667V4h5V2.667C7.556 1.194 6.436 0 5.056 0z"
                transform="translate(-964 -995) translate(964 995) translate(11 8)"
            />
            <path
                d="M5.056 7.125c.207 0 .375.168.375.375s-.168.375-.375.375c-.208 0-.375-.168-.375-.375s.167-.375.375-.375"
                transform="translate(-964 -995) translate(964 995) translate(11 8)"
            />
        </g>
    </svg>
);

export { Lighthouse, PhoneShadow };
