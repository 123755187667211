import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import { size } from '~/constants/Breakpoint';
import { Heading, Subheading } from '~/components/.base/headings';
import { WhiteButton } from '~/components/.base/buttons';
import { getHashCode } from 'helpers';
import { Lighthouse } from 'components/.base/Icons';

const MapItem = ({
    i,
    location,
    handleMarkerClick,
    currentLocation,
    openModal,
    activeLocation,
    setActiveLocationButton,
}) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });

    const locationButtonRef = useRef(null);

    useEffect(() => {
        console.log(activeLocation);
        if (activeLocation === i) {
            setActiveLocationButton(locationButtonRef?.current);
            console.log(locationButtonRef?.current);
        }
    }, [i, setActiveLocationButton, activeLocation]);

    return (
        <WhiteButton
            ref={locationButtonRef}
            aria-label={`Learn more about ${location.locationName}`}
            w="50px"
            h="50px"
            pd="0"
            onClick={() => (isMobile ? handleMarkerClick(i) : openModal(i))}
            className={`location-button no-stroke l${i + 1} ${
                currentLocation === i ? 'active' : null
            }`}
        >
            <Lighthouse hash={getHashCode(location.locationName)} />
            <span className="animation-1" />
            <span className="animation-2" />

            <div className={`location-popover l${i + 1}`} role="tooltip">
                <Heading as="h3" className="locationPopover__heading">
                    {location.locationName}
                </Heading>
                <Subheading className="locationPopover__content" as="p">
                    Lat. {location.locationLatitude} / Long. {location.locationLongitude}
                </Subheading>
            </div>
        </WhiteButton>
    );
};

export default MapItem;
