import React, { useState, useEffect, Fragment } from 'react';
import Modal from '~/components/.base/modal';
import Image from 'gatsby-image';
import { HeadingLarge, Subheading, BodyText, HeadingMegaXL } from '~/components/.base/headings';
import { WhiteButton } from '~/components/.base/buttons';
import { Grid, Row, Col } from '~/components/.base/containers';
import PlayButtonIcon from '~/images/icons/pdp/play-btn.svg';
import './LocationModal.scss';

const LocationModal = ({ modalIsOpen, setModalIsOpen, activeLocation, homePageData, onClose }) => {
    const [locationName, setLocationName] = useState('');
    const [locationInitials, setLocationInitials] = useState('');
    const [locationDesc, setLocationDesc] = useState('');
    const [locationLatitude, setLocationLatitude] = useState('');
    const [locationLongitude, setLocationLongitude] = useState('');
    const [locationImage, setLocationImage] = useState(null);

    useEffect(() => {
        if (modalIsOpen && document) {
            if (document) {
                if (document.querySelector(`div[role=dialog]`)) {
                    if (document.querySelector(`div[data-test=sentinelStart]`)) {
                        const elementNode = document.querySelector(`div[data-test=sentinelStart]`);
                        elementNode.remove();
                    }
                    if (document.querySelector(`div[data-test=sentinelEnd]`)) {
                        const elementNode = document.querySelector(`div[data-test=sentinelEnd]`);
                        elementNode.remove();
                    }
                }
            }
        }
    }, [modalIsOpen]);

    useEffect(() => {
        if (homePageData.locations && homePageData.locations.length) {
            setLocationName(homePageData.locations[activeLocation].locationName);
            setLocationInitials(homePageData.locations[activeLocation].locationInitials);
            setLocationDesc(homePageData.locations[activeLocation].locationDesc.locationDesc);
            setLocationLatitude(homePageData.locations[activeLocation].locationLatitude);
            setLocationLongitude(homePageData.locations[activeLocation].locationLongitude);
            if (homePageData.locations[activeLocation].locationImage) {
                setLocationImage(homePageData.locations[activeLocation].locationImage.fluid);
            }
        }
    }, [activeLocation, homePageData.locations]);

    return (
        <Modal
            open={modalIsOpen}
            onClose={() => {
                setModalIsOpen(false);
                onClose();
            }}
            ariaLabelledby="location-modal-title"
            top="0"
        >
            <Grid className="location-modal-body">
                <Row>
                    <Col size={4} pd="30px" className="left-half">
                        <div className="modal-coords">
                            <Subheading className="modal-coords-heading" as="p">
                                <span className="red">Lat.</span>
                                <br />
                                {locationLatitude}
                            </Subheading>
                            <Subheading className="modal-coords-heading" as="p">
                                <span className="red">Long.</span>
                                <br />
                                {locationLongitude}
                            </Subheading>
                        </div>
                        <div className="modal-main">
                            <HeadingLarge id="location-modal-title" as="h1">
                                {locationName}
                            </HeadingLarge>
                            <BodyText id="location-modal-description">{locationDesc}</BodyText>
                        </div>
                    </Col>
                    <Col size={6} className="right-half">
                        {locationImage ? (
                            <Fragment>
                                <Image fluid={locationImage} alt="" />
                                {homePageData.locations[activeLocation].usesVideo ? (
                                    <WhiteButton
                                        as="a"
                                        aria-label="Watch the video to experince Lobster's origin"
                                        href={homePageData.locations[activeLocation].videoLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        className='vimeo-link'
                                    >
                                        <PlayButtonIcon />
                                        <span className="animation-1" />
                                        <span className="animation-2" />
                                    </WhiteButton>
                                ) : null}
                            </Fragment>
                        ) : null}
                        <HeadingMegaXL className="locationInitials" as="p">
                            {locationInitials}
                        </HeadingMegaXL>
                    </Col>
                </Row>
            </Grid>
        </Modal>
    );
};

export default LocationModal;
