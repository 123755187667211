import React, { useEffect, useRef, useState } from 'react';
import Image from 'gatsby-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
import { css } from 'styled-components';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { getImage, getImageWidth } from 'helpers';
import { Default, Mobile } from '../../.base/responsive';
import { HeadingLarge, BodyText, Subheading, Heading } from 'components/.base/headings';
import { breakpoint } from 'constants/Breakpoint';
import { useBubbleImages } from '../../../hooks/use-bubble-images';
import { BackgroundDiv } from '~/components/.base/containers';
import PhoneFrame from '~/images/icons/homepage/phone_frame.svg';
import { NarrowContainer, Row, Col } from 'components/.base/containers';
import Slideshow from 'components/.base/slideshow';
import {
  FrameContentWrapper,
  HeroCol,
  PhoneFrameWrapper,
  SlideItem,
  SlideNav,
  StoreWrapper,
  TextWithImageSection,
} from './TextWithImage.styled';
import s from './TextWithImage.module.scss';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const TextWithImage = ({ homepageImages, homePageData }) => {
  const bubbleImages = useBubbleImages().edges;
  const { phoneShadow } = useStaticQuery(graphql`
    {
      phoneShadow: file(relativePath: { eq: "mobileApp/phone_shadow.png" }) {
        childImageSharp {
          fixed(width: 330) {
            ...GatsbyImageSharpFixed_withWebp
            aspectRatio
          }
        }
      }
    }
  `);
  gsap.registerPlugin(ScrollTrigger);

  const {
    textWithImageSectionAppStoreLink,
    textWithImageSectionGooglePlayLink,
    textWithImageSectionTextWithImageBlock,
    mobileAppSectionTitle,
  } = homePageData;
  const [slideIndex, setSlideIndex] = useState(0);

  const $ref = useRef(null);

  useEffect(() => {
    if ($ref?.current) {
      let stepProgress = 1 / textWithImageSectionTextWithImageBlock.length;
      let scrollTriggers = [];
      const buttons = document.querySelectorAll(`.${s.button}`);

      const st = ScrollTrigger.create({
        trigger: '.pinTrigger',
        start: 'center center',
        end: `center center-=${750 * textWithImageSectionTextWithImageBlock.length}px`,
        pin: '.pinElement',
        onUpdate: self => {
          const { progress, direction } = self;
          if (direction === 1) {
            // Scrolls down
            for (let i = 1; i >= 0; i = i - stepProgress) {
              if (progress > i) {
                setSlideIndex(i * textWithImageSectionTextWithImageBlock.length);
                break;
              }
            }
          } else {
            // Scrolls up
            for (let i = 0; i <= 1; i = i + stepProgress) {
              if (progress < i) {
                setSlideIndex(i * textWithImageSectionTextWithImageBlock.length - 1);
                break;
              }
            }
          }
        },
      });
      scrollTriggers.push(st);

      buttons.forEach(item => {
        item.addEventListener('click', e => {
          const slideTo = e.currentTarget.getAttribute('data-slide-to');
          let element = $ref.current;
          let toTop = 0;

          while (element.offsetParent.tagName !== 'BODY') {
            toTop += element.offsetTop;
            element = element.offsetParent;
          }

          toTop += element.offsetTop + 1125;

          st.scroll(toTop + 750 * slideTo);
        });
      });

      // Specify how to clean up after this effect:
      return () => {
        // all garbage cleanup all goes in a single useEffect for when component leaves
        if (scrollTriggers) {
          scrollTriggers.map(st => {
            st.kill();
            return true;
          });
        }
      };
    }
  }, [$ref, textWithImageSectionTextWithImageBlock.length]);

  const slideDots = textWithImageSectionTextWithImageBlock.map((item, i) => {
    let x = i % 2 === 0 ? 15 : 23,
      y = 15 + i * 55;
    return {
      x,
      y,
    };
  });

  return (
    <TextWithImageSection ref={$ref}>
      <BackgroundDiv className="imageLayer" top="0px" left="0px" w="100%">
        <Default>
          <Parallax className="water_sharp" y={['-100px', '-70px']}>
            <Image fluid={getImage(homepageImages, 'water-sharp')} alt="" />
          </Parallax>
        </Default>
        <Mobile>
          <Image fluid={getImage(homepageImages, 'water-sharp')} alt="" />
        </Mobile>
        <Default>
          <Parallax className="water_blurred" y={['-50px', '-100px']}>
            <Image fluid={getImage(homepageImages, 'water-blurred')} alt="" />
          </Parallax>
        </Default>
        <Mobile>
          <Image fluid={getImage(homepageImages, 'water-blurred')} alt="" />
        </Mobile>
      </BackgroundDiv>
      <div id="homepageMobileapp" className="pinTrigger">
        <div
          className="pinElement"
          css={css`
            position: relative;
            padding: 60px 10px;
            /* overflow: hidden; */
            display: none;
            @media ${breakpoint.lg} {
              padding: 170px 20px;
              display: block;
            }
          `}
        >
          <div>
            <Parallax className="bubbles_1" y={['0px', '-75px']}>
              <div
                css={css`
                  width: 100%;
                  @media (min-width: 950px) {
                    width: ${getImageWidth(bubbleImages, 'bubbles_1')}px;
                  }
                `}
              >
                <Image fluid={getImage(bubbleImages, 'bubbles_1')} alt="" />
              </div>
            </Parallax>
            <Default>
              <Parallax className="bubbles_2" y={['0px', '-300px']}>
                <div
                  css={css`
                    width: ${getImageWidth(bubbleImages, 'bubbles_2')}px;
                  `}
                >
                  <Image fluid={getImage(bubbleImages, 'bubbles_2')} alt="" />
                </div>
              </Parallax>
            </Default>
            <Default>
              <Parallax className="bubbles_3_top" y={['200px', '-200px']}>
                <div
                  css={css`
                    width: ${getImageWidth(bubbleImages, 'bubbles_3')}px;
                  `}
                >
                  <Image fluid={getImage(bubbleImages, 'bubbles_3')} alt="" />
                </div>
              </Parallax>
            </Default>
            <Default>
              <Parallax className="bubbles_3_bottom" y={['100px', '-100px']}>
                <div
                  css={css`
                    width: ${getImageWidth(bubbleImages, 'bubbles_3btm')}px;
                  `}
                >
                  <Image fluid={getImage(bubbleImages, 'bubbles_3btm')} alt="" />
                </div>
              </Parallax>
            </Default>
            <Default>
              <Parallax className="bubbles_4" y={['50px', '0px']}>
                <div
                  css={css`
                    width: ${getImageWidth(bubbleImages, 'bubbles_4')}px;
                  `}
                >
                  <Image fluid={getImage(bubbleImages, 'bubbles_4')} alt="" />
                </div>
              </Parallax>
            </Default>
            <Default>
              <Parallax className="bubbles_5" y={['50px', '0px']}>
                <div
                  css={css`
                    width: ${getImageWidth(bubbleImages, 'bubbles_5')}px;
                  `}
                >
                  <Image fluid={getImage(bubbleImages, 'bubbles_5')} alt="" />
                </div>
              </Parallax>
            </Default>
            <Default>
              <Parallax className="bubbles_6" y={['-50px', '50px']}>
                <div
                  css={css`
                    width: ${getImageWidth(bubbleImages, 'bubbles_6')}px;
                  `}
                >
                  <Image fluid={getImage(bubbleImages, 'bubbles_6')} alt="" />
                </div>
              </Parallax>
            </Default>
          </div>
          <SlideNav>
            <div className="slideNav__inner">
              <svg width="41" height="314" viewBox="0 0 41 314" aria-hidden="true">
                <defs>
                  <circle id="fh7846cooa" cx="15" cy="15" r="11" />
                  <mask
                    id="n5xf2p67ef"
                    width="22"
                    height="22"
                    x="0"
                    y="0"
                    fill="#fff"
                    maskContentUnits="userSpaceOnUse"
                    maskUnits="objectBoundingBox"
                  >
                    <use xlinkHref="#fh7846cooa" />
                  </mask>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g>
                    <path
                      stroke="#8B9A9C"
                      strokeDasharray="2 2"
                      strokeWidth="2"
                      d="M18.474 15C25.366 42.123 41 40 26 72c-8.35 17.815-24 29 0 80 7.065 15.013-15 43.5-4.994 71.39C31.61 252.948 29.941 277.485 16 297"
                    />
                  </g>
                </g>
              </svg>
              {slideDots.map((slideDot, i) => (
                <button
                  key={i}
                  data-slide-to={i}
                  aria-label={`Scroll to ${textWithImageSectionTextWithImageBlock[i].title}`}
                  className={`${s.button} ${i === slideIndex ? s.active : ''}`}
                  css={css`
                    left: ${slideDot.x - 13}px;
                    top: ${slideDot.y - 13}px;
                  `}
                  // onClick={() => setSlideIndex(i)}
                >
                  <span className={`${s.button__iconWrapper}`}>
                    <span className={`${s.button__iconInner}`}></span>
                    <span className={`${s.button__iconDot}`}></span>
                  </span>
                </button>
              ))}
            </div>
          </SlideNav>
          <NarrowContainer
            css={css`
              position: relative;
              z-index: 1;
            `}
          >
            <Row
              css={css`
                flex-wrap: wrap;
                @media ${breakpoint.maxMd} {
                  display: none;
                }
              `}
            >
              <Col size="1">
                <HeroCol>
                  <PhoneFrameWrapper>
                    <FrameContentWrapper>
                      {textWithImageSectionTextWithImageBlock.map(({ mobilePreview }, i) => (
                        <SlideItem active={slideIndex === i} key={i}>
                          <Image fluid={mobilePreview.fluid} alt={mobilePreview.description} />
                        </SlideItem>
                      ))}
                    </FrameContentWrapper>
                    <PhoneFrame className="frame" />
                    <Image className="shadow" fixed={phoneShadow.childImageSharp.fixed} alt="" />
                  </PhoneFrameWrapper>
                </HeroCol>
              </Col>
              <Col size="1">
                <HeroCol>
                  {textWithImageSectionTextWithImageBlock.map(({ heading, description }, i) => (
                    <SlideItem active={slideIndex === i} key={i}>
                      <HeadingLarge
                        as="h2"
                        css={css`
                          padding-top: 40px;
                          margin-bottom: 30px;
                        `}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: heading,
                          }}
                        ></span>
                      </HeadingLarge>
                      <BodyText
                        css={css`
                          margin-bottom: auto;
                        `}
                      >
                        {description.description}
                      </BodyText>
                    </SlideItem>
                  ))}
                  <StoreWrapper
                    href={textWithImageSectionAppStoreLink}
                    rel="noreferrer"
                    target="_blank"
                    css={css`
                      margin-top: 60px;
                      margin-bottom: 14px;
                      @media ${breakpoint.lg} {
                        margin-top: 130px;
                      }
                    `}
                  >
                    <div>
                      <Image fluid={getImage(homepageImages, 'apple-logo')} alt="App Store" />
                    </div>
                    <div>
                      <Subheading as="p">DOWNLOAD ON THE</Subheading>
                      <Heading as="p">App Store</Heading>
                    </div>
                  </StoreWrapper>
                  <StoreWrapper
                    href={textWithImageSectionGooglePlayLink}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div>
                      <Image fluid={getImage(homepageImages, 'play-store-logo')} alt="Play Store" />
                    </div>
                    <div>
                      <Subheading as="p">Get it on</Subheading>
                      <Heading as="p">Google Play</Heading>
                    </div>
                  </StoreWrapper>
                </HeroCol>
              </Col>
            </Row>
          </NarrowContainer>
        </div>

        <div
          css={css`
            padding: 60px 10px;
            overflow: hidden;
            @media ${breakpoint.lg} {
              padding: 170px 20px;
              display: none;
            }
            .carousel {
              .control-dots {
                .circle .inner {
                  background-color: rgba(92, 101, 102, 0.7);
                }
              }
            }
            .slide {
              text-align: left;
            }
          `}
        >
          <Slideshow
            carouselLabel={mobileAppSectionTitle}
            arrowPosition={{
              top: 'auto',
              bottom: '0px',
              prev: {
                left: '20px',
                right: 'auto',
              },
              next: {
                left: '60px',
                right: 'auto',
              },
            }}
            dotPosition={{
              bottom: '0',
              right: 'auto',
              left: '100',
              width: 'fit-content',
            }}
          >
            {textWithImageSectionTextWithImageBlock.map((slide, i) => {
              return (
                <Row
                  key={i}
                  css={css`
                    flex-wrap: wrap;
                  `}
                >
                  <Col size="1">
                    <HeroCol>
                      <PhoneFrameWrapper>
                        <FrameContentWrapper>
                          <Image
                            fluid={slide.mobilePreview.fluid}
                            alt={slide.mobilePreview.description}
                          />
                        </FrameContentWrapper>
                        <PhoneFrame className="frame" />
                        <Image
                          className="shadow"
                          fixed={phoneShadow.childImageSharp.fixed}
                          alt=""
                        />
                      </PhoneFrameWrapper>
                    </HeroCol>
                  </Col>
                  <Col
                    size="1"
                    css={css`
                      @media ${breakpoint.maxMd} {
                        order: -1;
                      }
                    `}
                  >
                    <HeroCol>
                      <div>
                        <HeadingLarge
                          as="h2"
                          css={css`
                            padding-top: 40px;
                            margin-bottom: 30px;
                          `}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: slide.heading,
                            }}
                          ></span>
                        </HeadingLarge>
                        <BodyText
                          css={css`
                            margin-bottom: auto;
                          `}
                        >
                          {slide.description.description}
                        </BodyText>
                      </div>
                      <div
                        css={css`
                          display: none;
                          @media ${breakpoint.md} {
                            display: block;
                          }
                        `}
                      >
                        <StoreWrapper
                          href={textWithImageSectionAppStoreLink}
                          rel="noreferrer"
                          target="_blank"
                          css={css`
                            margin-top: 60px;
                            margin-bottom: 14px;
                            @media ${breakpoint.lg} {
                              margin-top: 130px;
                            }
                          `}
                        >
                          <div>
                            <Image fluid={getImage(homepageImages, 'apple-logo')} alt="App Store" />
                          </div>
                          <div>
                            <Subheading as="p">DOWNLOAD ON THE</Subheading>
                            <Heading as="p">App Store</Heading>
                          </div>
                        </StoreWrapper>
                        <StoreWrapper
                          href={textWithImageSectionGooglePlayLink}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <div>
                            <Image
                              fluid={getImage(homepageImages, 'play-store-logo')}
                              alt="Play Store"
                            />
                          </div>
                          <div>
                            <Subheading as="p">Get it on</Subheading>
                            <Heading as="p">Google Play</Heading>
                          </div>
                        </StoreWrapper>
                      </div>
                    </HeroCol>
                  </Col>
                </Row>
              );
            })}
          </Slideshow>
          <div
            css={css`
              @media ${breakpoint.md} {
                display: none;
              }
            `}
          >
            <StoreWrapper
              href={textWithImageSectionAppStoreLink}
              rel="noreferrer"
              target="_blank"
              css={css`
                margin-top: 60px;
                margin-bottom: 14px;
                @media ${breakpoint.lg} {
                  margin-top: 130px;
                }
              `}
            >
              <div>
                <Image fluid={getImage(homepageImages, 'apple-logo')} alt="App Store" />
              </div>
              <div>
                <Subheading as="p">DOWNLOAD ON THE</Subheading>
                <Heading as="p">App Store</Heading>
              </div>
            </StoreWrapper>
            <StoreWrapper
              href={textWithImageSectionGooglePlayLink}
              rel="noreferrer"
              target="_blank"
            >
              <div>
                <Image fluid={getImage(homepageImages, 'play-store-logo')} alt="Play Store" />
              </div>
              <div>
                <Subheading as="p">Get it on</Subheading>
                <Heading as="p">Google Play</Heading>
              </div>
            </StoreWrapper>
          </div>
        </div>
      </div>
    </TextWithImageSection>
  );
};

export default TextWithImage;
