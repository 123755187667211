import React, { useEffect, useRef } from "react";

import { BodyTextAlt, Heading } from "~/components/.base/headings";
import { RedButton } from "~/components/.base/buttons";

const SideItem = ({
  i,
  loc,
  openModal,
  activeLocation,
  setActiveLocationButtonMobile
}) => {
  const locationButtonRef = useRef(null);

  useEffect(() => {
    if (i === activeLocation) {
      setActiveLocationButtonMobile(locationButtonRef?.current);
    }
  }, [i, activeLocation, setActiveLocationButtonMobile]);
  return (
    <div className="slide-item">
      <Heading>{loc.locationName}</Heading>
      <BodyTextAlt>
        <span className="red">LAT.</span> {loc.locationLatitude}{" "}
        <span className="red">/</span>
        <br /> <span className="red">LONG.</span> {loc.locationLongitude}
      </BodyTextAlt>
      <RedButton
        aria-label={`Learn more about ${loc.locationName}`}
        ref={locationButtonRef}
        onClick={() => openModal(i)}
      >
        Learn more
      </RedButton>
    </div>
  );
};

export default SideItem;
