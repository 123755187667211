const meta = {
  google: {
    siteVerification: {
      name: `google-site-verification`,
      content: `lBoYDuZxn9dMtOsMAZXFSqAjWgOxELtRmwAXc8g2FeU`,
    },
    shoppingVerification: {
      name: `google-site-verification`,
      content: `Go8v8xFvaYEYNVWdr4CIkJE4Nefqo10A80mHvyvrbCQ`,
    },
    openMoves1: {
      name: `google-site-verification`,
      content: `xGA2HqsFUdQe81rthRqRo0srNPP2_kpDX-wSnSWP4Io`,
    },
    openMoves2: {
      name: `google-site-verification`,
      content: `vs4uptLDfQ-WO69_1VxAXFT4OZY4gYT-ehNBCwK9ChY`,
    },
  },
  facebook: {
    name: `facebook-domain-verification`,
    content: `zud1thbo53568pw4596p5fm881q4n6`,
  },
};

export default meta;
