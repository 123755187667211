import { useStaticQuery, graphql } from 'gatsby'

export const useBubbleImages = () => {
    const { bubbleImages } = useStaticQuery(
        graphql`
            query BubblesQuery{
                bubbleImages: allFile(
                    filter: {
                        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                        absolutePath: { regex: "/images/bubbles/" }
                    }
                ) {
                    edges {
                        node {
                            name
                            childImageSharp {
                                fluid(maxWidth: 1600, quality: 80) {
                                    aspectRatio
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                                original {
                                    width
                                }
                            }
                        }
                    }
                }
            }        
        `
    )
    return bubbleImages
}