import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { Subheading, HeadingLarge, BodyText } from '~/components/.base/headings';
import { LargeRedButton } from '~/components/.base/buttons';
import { BorderedImage } from '~/components/.base/containers';
import './Franchise.scss';
import { WoodenBorder } from '../../.base/border';

const Franchise = ({ homepageImages, bgImages, homePageData }) => {
    return (
        <div className="franchise">
            <div>
                <BorderedImage className="img-container">
                    <WoodenBorder zIndex="0" />
                    <WoodenBorder top="100%" zIndex="0" />
                    <Image
                        fluid={homePageData.franchiseSectionBannerImage.fluid}
                        alt=""
                        style={{ zIndex: '-2' }}
                        durationFadeIn={100}
                    />
                </BorderedImage>
                <div id="homepageOwnafranchise" className="d-flex" tabIndex="-1">
                    <div className="left half">
                        <Subheading as="p" className="preHeading">
                            {homePageData.franchiseSectionSubtitle}
                        </Subheading>
                        <HeadingLarge as="h2">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: homePageData.franchiseSectionTitle,
                                }}
                            ></span>
                        </HeadingLarge>
                        <BodyText>
                            {homePageData.franchiseSectionDesc.franchiseSectionDesc}
                        </BodyText>
                        <Link to="/franchise/">
                            <LargeRedButton>
                                {homePageData.franchiseSectionButtonText}
                            </LargeRedButton>
                        </Link>
                    </div>
                    <div className="right half">
                        <div className="img-container">
                            <Image
                                fluid={homePageData.franchiseFoodImage.fluid}
                                style={{ overflow: 'visible' }}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Franchise;
