import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import Slideshow from '~/components/.base/slideshow';
import { CenteredContainer } from '~/components/.base/containers';
import { HeadingLarge } from '~/components/.base/headings';
import { RedButton } from '~/components/.base/buttons';
import ParallaxSlide from './ParallaxSlide';
import StoreContext from '~/layouts/StoreContext';
import DotTrailIcon from '~/images/icons/checkout/dot-trail.svg';

import './Hero.scss';
import { WoodenBorder } from '../../.base/border';
import { breakpoint, size } from '../../../constants/Breakpoint';
import { WhiteButton } from 'components/.base/buttons';

const HeroWrapper = styled.div`
  @media ${breakpoint.md} {
    .carousel {
      .prev-next {
        width: 70px;
        height: 70px;
        padding: 20px;
        border-radius: 50%;
        background-clip: content-box;
      }
      .prev {
        border: 1px solid transparent;
      }
      .next {
        border: 1px solid #fff;
        svg {
          overflow: visible;
          > * {
            display: none;
          }
          .homepageHeroExtended {
            display: block;
          }
          g,
          path {
            position: absolute;
            right: 0;
          }
        }
        &:hover,
        &:focus {
          border-color: #d4212c;
        }
      }
    }
  }
`;

const Hero = ({ slideData, homepageImages, hideParallax, homepageData, onSlideChanged }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  gsap.registerPlugin(MorphSVGPlugin);

  const { setHeaderWhite } = useContext(StoreContext);

  const handleChange = i => {
    if (hideParallax) {
      setHeaderWhite(slideData[i].whiteHeaderText);
    } else {
      if (i > 0) {
        setHeaderWhite(slideData[i - 1].whiteHeaderText);
      } else {
        setHeaderWhite(false);
      }
    }
    onSlideChanged(i);
  };
  useEffect(() => {
    if (hideParallax && slideData.length) {
      setHeaderWhite(slideData[0].whiteHeaderText);
    }
  }, [slideData, hideParallax]);

  if (!slideData.length) return null;
  if (hideParallax) {
    return (
      <HeroWrapper className="index-hero">
        {slideData.length > 1 ? (
          <>
            <Slideshow
              carouselLabel="Homepage hero"
              noLoop={true}
              handleChange={handleChange}
              data={slideData}
              arrowPosition={{
                top: 'auto',
                bottom: '70px',
                prev: {
                  left: 'auto',
                  right: '130px',
                },
                next: {
                  left: 'auto',
                  right: '40px',
                },
              }}
              dotPosition={{
                top: 'auto',
                bottom: '20px',
                left: 'auto',
                right: '30px',
                width: 'auto',
              }}
            >
              {slideData.map((slide, i) => (
                <CenteredContainer key={i} className="slide-img">
                  <div className="slide-img-wrapper">
                    <Image
                      fluid={
                        isMobile && slide.mobileImage
                          ? slide.mobileImage.fluid
                          : slide.slideImage.fluid
                      }
                      className="slide-img-up"
                      loading={i === 0 ? 'eager' : 'lazy'}
                      fadeIn={false}
                    />
                  </div>
                  {slide.useOverlay ? (
                    <div
                      className="overlay"
                      style={{
                        backgroundColor: `rgba(0, 0, 0, ${slide.overlayOpacity})`,
                      }}
                    />
                  ) : null}
                  <CenteredContainer className="details">
                    <HeadingLarge
                      as="h2"
                      className={`slide-title ${slide.whiteHeaderText ? 'white' : ''}`}
                      md="0 0 2rem"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: slide.title,
                        }}
                      ></span>
                    </HeadingLarge>
                    <RedButton as={Link} to={slide.buttonLink} className="cta">
                      {slide.buttonText}
                    </RedButton>
                    <HeadingLarge as="p" className="indexes">
                      <span class="sr-only">Current slide</span>
                      {slide.whiteHeaderText ? (
                        <span className="white">
                          0{i + 1}
                          <span className="sm">
                            <span className="big-space">/</span> 0{slideData.length + 1}
                          </span>
                        </span>
                      ) : (
                        <span>
                          0{i + 1}
                          <span className="sm">
                            <span className="big-space">/</span> 0{slideData.length + 1}
                          </span>
                        </span>
                      )}
                    </HeadingLarge>
                  </CenteredContainer>
                  <div className="scroll-for-more">
                    <WhiteButton as="span" className="scroll-for-more-text">
                      Scroll for more
                    </WhiteButton>
                    <DotTrailIcon />
                  </div>
                </CenteredContainer>
              ))}
            </Slideshow>
          </>
        ) : (
          <div className="slide single">
            <CenteredContainer className="slide-img">
              <div className="slide-img-wrapper">
                <Image
                  fluid={
                    isMobile && slideData[0].mobileImage
                      ? slideData[0].mobileImage.fluid
                      : slideData[0].slideImage.fluid
                  }
                  className="slide-img-up"
                  loading="eager"
                  fadeIn={false}
                />
              </div>
              {slideData[0].useOverlay ? (
                <div
                  className="overlay"
                  style={{
                    backgroundColor: `rgba(0, 0, 0, ${slideData[0].overlayOpacity})`,
                  }}
                />
              ) : null}
              <CenteredContainer className="details">
                <HeadingLarge
                  className={`slide-title ${slideData[0].whiteHeaderText ? 'white' : ''}`}
                  md="0 0 2rem"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: slideData[0].title,
                    }}
                  ></span>
                </HeadingLarge>
                <RedButton as={Link} to={slideData[0].buttonLink} className="cta">
                  {slideData[0].buttonText}
                </RedButton>
              </CenteredContainer>
              <div className="scroll-for-more">
                <WhiteButton as="span" className="scroll-for-more-text">
                  Scroll for more
                </WhiteButton>
                <DotTrailIcon />
              </div>
            </CenteredContainer>
          </div>
        )}
        <div className="scroll-for-more">
          <WhiteButton as="span" className="scroll-for-more-text">
            Scroll for more
          </WhiteButton>
          <DotTrailIcon />
        </div>
      </HeroWrapper>
    );
  }
  return (
    <HeroWrapper id="homepageIntro" className="index-hero section" tabIndex="-1">
      <Slideshow
        carouselLabel="Homepage hero"
        noLoop={true}
        handleChange={handleChange}
        data={slideData}
        arrowPosition={{
          top: 'auto',
          bottom: '70px',
          prev: {
            left: 'auto',
            right: '130px',
          },
          next: {
            left: 'auto',
            right: '40px',
          },
        }}
        dotPosition={{
          top: 'auto',
          bottom: '20px',
          left: 'auto',
          right: '30px',
          width: 'auto',
        }}
      >
        <ParallaxSlide
          homepageImages={homepageImages}
          slideData={slideData}
          homepageData={homepageData}
        />
        {slideData.map((slide, i) => (
          <CenteredContainer key={i} className="slide-img">
            <div className="slide-img-wrapper">
              <Image
                fluid={
                  isMobile && slide.mobileImage ? slide.mobileImage.fluid : slide.slideImage.fluid
                }
                className="slide-img-up"
                loading="lazy"
              />
            </div>
            {slide.useOverlay ? (
              <div
                className="overlay"
                style={{
                  backgroundColor: `rgba(0, 0, 0, ${slide.overlayOpacity})`,
                }}
              />
            ) : null}
            <CenteredContainer className="details">
              <HeadingLarge
                as="h2"
                className={`slide-title ${slide.whiteHeaderText ? 'white' : ''}`}
                md="0 0 2rem"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: slide.title,
                  }}
                ></span>
              </HeadingLarge>
              <RedButton as={Link} to={slide.buttonLink} className="cta">
                {slide.buttonText}
              </RedButton>
              <HeadingLarge as="p" className="indexes">
                <span class="sr-only">Current slide</span>
                {slide.whiteHeaderText ? (
                  <span className="white">
                    0{i + 2}
                    <span className="sm">
                      <span className="big-space">/</span> 0{slideData.length + 1}
                    </span>
                  </span>
                ) : (
                  <span>
                    0{i + 2}
                    <span className="sm">
                      <span className="big-space">/</span> 0{slideData.length + 1}
                    </span>
                  </span>
                )}
              </HeadingLarge>
            </CenteredContainer>
          </CenteredContainer>
        ))}
      </Slideshow>
      <div className="scroll-for-more">
        <WhiteButton as="span" className="scroll-for-more-text">
          Scroll for more
        </WhiteButton>
        <DotTrailIcon />
      </div>
      <WoodenBorder zIndex="0" />
    </HeroWrapper>
  );
};

export default Hero;
